import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Button, Form, Modal, Icon } from 'semantic-ui-react'
import { RightDivider, TargetNumberInput } from '@code-yellow/spider'
import { isFeatureFlagEnabled } from 'helpers/featureFlags'
import { modalColors } from 'styles'

@observer
export default class QuantityModal extends Component {
  static propTypes = {
    quantity: PropTypes.number,
    onSelect: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  constructor(...args) {
    super(...args)

    this.onSubmit = this.onSubmit.bind(this)
    this.value = this.props.quantity
  }

  @observable value = 1
  @observable showWarning = false

  onSubmit() {
    const { onSelect } = this.props
    if (this.value !== null) {
      onSelect(this.value)
    }
  }

  render() {
    const { onClose, quantity } = this.props

    return (
      <Modal open closeIcon data-test-quantity-modal onClose={onClose} size="tiny" closeOnDimmerClick={false}>
        <Modal.Header style={{ backgroundColor:modalColors.greenYellow }}>{t('workStation.production.quantityModal.title')}</Modal.Header>
        <Modal.Content>
          {this.showWarning && (
            <div>
              <Icon style={{ color: '#EBBB12', marginRight: '0.5em' }} name={'warning sign'} />
              {this.showWarning && t('workStation.production.quantityModal.quantityWarning', { quantity: quantity })}
            </div>
          )}
          <Form onSubmit={this.onSubmit}>
            <TargetNumberInput autoFocus allowDecimal
              label={t('workStation.production.quantityModal.quantity')}
              value={this.value === null ? '' : this.value.toString()}
              onChange={(value) => {
                // Show warning only once and then allow to continue
                if (
                  !this.showWarning &&
                  value !== '' && parseInt(value) > quantity &&
                  !isFeatureFlagEnabled('allow_receipt_of_more_than_ordered')
                ) {
                  this.showWarning = true
                  this.value = null
                  return
                }
                this.value = value === '' ? null : parseInt(value)
              }}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <RightDivider />
          <Button primary data-test-confirm-button
            icon="check"
            labelPosition="left"
            content={t('workStation.production.quantityModal.confirmButton')}
            onClick={this.onSubmit}
            disabled={this.value === null}
          />
        </Modal.Actions>
      </Modal>
    )
  }
}
