import React from 'react'
import moment from 'moment'
import { Table, Icon, Popup } from 'semantic-ui-react'

// components
import MaterialPlanPopUp from 'component/MaterialPlanPopUp';
// end components

// helpers
import { humanReadable } from 'helpers/decimal';
import { isFeatureFlagEnabled } from 'helpers/featureFlags'
import { format } from 'helpers/date'
import { BigDot,  getMaxDates } from 'screen/Planner/helpers'
// end helpers

/**
 * Returns the settings for the admin overview to show the traffic lights
 *
 * We have four traffic lights:
 *
 * (1) If we are on time scheduling wise (is it planned in before the deadline)
 * (2) Stock, do we have enough stock at the correct location
 * (3) If our purchase orders are on time (are our dependencies delivered on time
 * (4) Do we have enough people on the workstations. Is the workstation avaiable during the time frame
 *
 * @param fteInformation
 * @param period
 * @param getEndAt
 * @returns {[{cellProps: {"data-test-delivery-check": boolean}, label: JSX.Element, attr: (function(*))},{cellProps: {"data-test-stock-materials": boolean}, label: JSX.Element, attr: (function(*))},{cellProps: {"data-test-incoming-lines": boolean}, label: JSX.Element, attr: (function(*))},{cellProps: {"data-test-capacity": boolean}, label: JSX.Element, attr: ((function(*): (*))|*)}]}
 */
export default function trafficLights(fteInformation, period, getEndAt = null, fromScheduler = false) {
  return [
    {
      label: (
        <Popup flowing
          trigger={<Icon name="clock" />}
          content={'End date production order versus delivery date of sales order line'}
        />
      ),
      attr: (productionRequest) => {
        const productionOrder = productionRequest.productionOrder

        let relatedSalesOrderLines = []
        if (!productionOrder.isNew && isFeatureFlagEnabled('allocations')) {
          productionOrder.resourceAllocations.map(ra => relatedSalesOrderLines.push(ra.salesOrderLine))
        }
        // Add productionOrder.salesOrderLine if it is not new and not already in relatedSalesOrderLines
        if (!productionOrder.salesOrderLine.isNew && relatedSalesOrderLines.filter(s => s.id === productionOrder.salesOrderLine.id).length === 0) {
          relatedSalesOrderLines.push(productionOrder.salesOrderLine)
        }

        let minDeliveryDates = null
        if (relatedSalesOrderLines.length > 0) {
          minDeliveryDates = moment.min(relatedSalesOrderLines.map(sa => sa.salesOrder.deliveryDate))
        }


        const label = <BigDot color={productionRequest.outboundTrafficLight} />
        return (
          <Popup flowing hoverable
            trigger={label}
            content={
              relatedSalesOrderLines.length === 0
                ? t('workloadPlanner.fields.noAllocation.label')
                : (
                  <div>
                    {relatedSalesOrderLines.map(sa => <>{sa.salesOrder.getLink()} / {sa.number}</>)}
                    {minDeliveryDates !== null && <div>{t('workloadPlanner.fields.earliestDeliveryDate.label')}: {format(minDeliveryDates)}</div>}
                  </div>
                )
            }
          />
        )
      },
      cellProps: (productionRequest) => ({
        collapsing: true,
        'data-test-delivery-check': true,
      }),
    },
    // WIP waiting for T39152
    {
      label: (
        <Popup flowing
          trigger={<Icon name="boxes" />}
          content={'Warehouse stock of material plan'}
        />
      ),
      attr: (productionRequest) => {
        const color = productionRequest.materialPlanTrafficLight;

        return (
          <MaterialPlanPopUp
            trigger={<BigDot color={color} />}
            productionRequest={productionRequest}
            fromScheduler={fromScheduler}
          />
        )
      },
      cellProps: (productionRequest) => ({
        collapsing: true,
        'data-test-stock-materials': true,
      }),
    },
    {
      label: (
        <Popup flowing
          trigger={<Icon name="calendar checked" />}
          content={'Start date production order versus receipt date of purchase orders lines'}
        />
      ),
      attr: (productionRequest) => {
        const productionOrder = productionRequest.productionOrder
        const purchaseOrderLines = !productionOrder.isNew ? productionOrder.purchaseOrderLines : []
        const purchaseOrders = !productionOrder.isNew ? productionOrder.purchaseOrders : []
        const maxReceiptDates = getMaxDates(purchaseOrders, purchaseOrderLines)

        const label = <BigDot color={productionRequest.inboundTrafficLight} />
        return (
          <Popup flowing hoverable
            trigger={label}
            content={purchaseOrderLines.length === 0
              ? t('workloadPlanner.fields.noPurchaseOrderLines.label')
              : (
                <div>
                  <Table data-test-purchase-order-lines-table compact style={{ width: '200px' }}>
                    <Table.Header>
                      <Table.HeaderCell>Purchase Order</Table.HeaderCell>
                      <Table.HeaderCell>Status</Table.HeaderCell>
                      <Table.HeaderCell>Line Number</Table.HeaderCell>
                      <Table.HeaderCell>#</Table.HeaderCell>
                      <Table.HeaderCell>Receipt Date</Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                      {purchaseOrderLines.map(puLine => (
                        <Table.Row>
                          <Table.Cell collapsing>
                            {puLine.purchaseOrder.getLink()} {!puLine.purchaseOrder.exactGlobePurchaseOrder.isNew && puLine.purchaseOrder.exactGlobePurchaseOrder.getLink()}
                          </Table.Cell>
                          <Table.Cell collapsing>{puLine.purchaseOrder.status}</Table.Cell>
                          <Table.Cell collapsing>{puLine.number}</Table.Cell>
                          <Table.Cell collapsing>{humanReadable(puLine.quantity)}</Table.Cell>
                          <Table.Cell collapsing>{format(puLine.receiptDate)}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                  {maxReceiptDates !== null && <div>{t('workloadPlanner.fields.highestReceiptDate.label')}: {format(maxReceiptDates)}</div>}
                </div>
              )
            }
          />
        )
      },
      cellProps: (productionRequest) => ({
        collapsing: true,
        'data-test-incoming-lines': true,
      }),
    },
    {
      label: (
        <Popup flowing
          trigger={<Icon name="users" />}
          content={'Capacity'}
        />
      ),
      attr: (productionRequest) => {
        const color = productionRequest.capacityTrafficLight;
        // Not nicely implemented yet
        return  <BigDot color={color} />;


      },
      cellProps: (productionRequest) => ({
        collapsing: true,
        'data-test-capacity': true,
      }),
    },
  ]
}

