import React, { Component } from 'react';
import { observable, action } from 'mobx'
import PropTypes from 'prop-types';
import { observer } from 'mobx-react'
import { Button } from 'semantic-ui-react'
import { RightDivider } from '@code-yellow/spider'
import moment from 'moment'

// helpers
import { showSaveNotification } from 'helpers/notification'
import { StyledToolbar, Container, Stats } from './helpers'
import { onEnter } from 'helpers'
import { humanReadable } from 'helpers/decimal'
// end helpers

// stores
import { Step } from 'store/Step';
import { ProductionRequest } from 'store/ProductionRequest';
import { BatchStore } from 'store/Batch';
// end stores


@observer
export default class PerformStep extends Component {
    static propTypes = {
        productionRequest: PropTypes.instanceOf(ProductionRequest).isRequired,
        step: PropTypes.instanceOf(Step).isRequired,
        onPerform: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        stats: PropTypes.object.isRequired,
        prev: PropTypes.string.isRequired,
        maxPerformCount: PropTypes.number.isRequired,
        selectedPerformCount: PropTypes.number.isRequired,
    }


    @observable startedAt = null
    @observable data
    @observable errors = []


    constructor(...args) {
        super(...args)

        this.onSubmit = this.onSubmit.bind(this)
        this.onSubmitAndClose = this.onSubmitAndClose.bind(this)
        this.onKeyPress = onEnter(this.onSubmitAndClose)

        this.data = {}
    }


    componentDidMount() {
        this.startedAt = moment()
    }

    async onSubmit(quantity) {
        const { onPerform } = this.props

        this.errors = []
        return await onPerform({ ...this.data, quantity }, this.startedAt)
    }

    @action
    onSubmitAndClose(goToMain = false, nextBatch = false) {
        const { step, onClose, productionRequest, prev, stats } = this.props
        if (nextBatch) {
            const hasPreviousStep = step.previousSteps.models.length > 0
            const stepParams = hasPreviousStep
                ? { '.last_step.next_step.id': step.id }
                : { '.last_step:isnull': true, '.production_request.process_version.id': productionRequest.processVersion.id }

            return this.onSubmit().then(showSaveNotification).then(() => {
                const batchStore = new BatchStore({
                    relations: [
                        'productionRequest',
                    ],
                    params: {
                        ...stepParams,
                        '.production_request.finished': false,
                        '.scrap_reason': null,
                    }
                })
                batchStore.fetch().then(() => {
                    if (batchStore.models.length > 0) {
                        const nextProductionRequest = batchStore.models[0].productionRequest
                        window.location = `/operations/production-request/${nextProductionRequest.id}/perform`
                    } else {
                        window.location = '/operations/production-request/overview'
                    }
                })
            })
        }

        // T38400: Auto-matically go back to main order expanded.
        const ASK_PIETER_MAGIC_NUMBER = 1 // TODO: Ask Pieter why it should be 1. Ideally we get it from production_request.quantity / batch.quantity or something, but not a hardcoded 1.
        const lastStep = (step.nextStep === null || step.nextStep?.id === null) && stats.quantityInProgress.equals(ASK_PIETER_MAGIC_NUMBER) && stats.quantityDone.equals(stats.quantity.sub(ASK_PIETER_MAGIC_NUMBER)) && stats.quantityTodo.equals(0)
        const goToMainExpanded = lastStep && ['buy', 'sell', 'transfer_line'].includes(productionRequest.processVersion.batchType.type)
        const goToLines = step.nextStep.type === 'subprocesses' && ['pick_order', 'receive_order', 'transfer'].includes(productionRequest.processVersion.batchType.type)
        // goToMain
        if (goToMain || goToMainExpanded || goToLines) {
            return this.onSubmit().then(showSaveNotification).then(onClose).then(() => {
                if (goToMain) {
                    window.location = `/operations/production-request/overview/${productionRequest.superrequest.id}`
                }
                if (goToMainExpanded || goToLines) {
                    window.location = goToMainExpanded ? prev : `/operations/production-request/overview/${productionRequest.id}`
                }
            })
        } else {
            return this.onSubmit().then(showSaveNotification).then(onClose)
        }
    }

    onConfirm = (print, toMain = false, toNextBatch = false) => {
        const { productionRequest, onClose } = this.props

        if (toMain) {
            if (print) {
                const requestId = productionRequest.superrequest?.id ?? productionRequest.id;
                window.location = `/operations/production-request/overview/${requestId}?.id=${requestId}`
            } else {
                this.onSubmitAndClose(toMain, false)
            }
        } else if (toNextBatch) {
            this.onSubmitAndClose(false, true)
        } else if (print) {
            onClose()
        } else {
            this.onSubmitAndClose(toMain)
        }
    }

    renderStats() {
        const { stats } = this.props

        return (
            <Stats style={{ float: 'right' }}>
                <span data-test-production-progress-todo>{t('progress.productionLine.stat.todo')} <span>{humanReadable(stats.quantityTodo)}</span></span>
                <span data-test-production-progress-ip>{t('progress.productionLine.stat.inProgress')} <span>{humanReadable(stats.quantityInProgress)}</span></span>
                <span data-test-production-progress-done>{t('progress.productionLine.stat.done')} <span>{humanReadable(stats.quantityDone)}</span></span>
            </Stats>
        )
    }


    renderButtons() {
        const { step, selectedPerformCount } = this.props

        const splitPrint = step.type === 'split' && step.splitStep.type === 'print'
        const print = splitPrint || step.type === 'print' || step.type === 'carrier'

        return (
            <>
                <Button primary data-test-confirm-button
                    icon="check"
                    labelPosition="left"
                    content={t('workStation.production.performModal.confirmButton', { count: humanReadable(selectedPerformCount) })}
                    onClick={() => this.onConfirm(print, false, false)}
                />
                <Button primary data-test-confirm-and-to-overview-button
                    icon="check"
                    labelPosition="left"
                    content={t('workStation.production.performModal.confirmAndOverviewButton', { count: humanReadable(selectedPerformCount) })}
                    onClick={() => this.onConfirm(print, true, false)}
                />
                <Button primary data-test-confirm-and-to-next-batch-button
                    icon="check"
                    labelPosition="left"
                    content={t('workStation.production.performModal.confirmAndNextBatchButton', { count: humanReadable(selectedPerformCount) })}
                    onClick={() => this.onConfirm(print, false, true)}
                />
            </>
        )
    }

    renderContent() {
        return <Container></Container>
    }

    render() {
        return (
            <>
                {this.renderContent()}
                <StyledToolbar>
                    {this.renderStats()}
                    <RightDivider />
                    {this.renderButtons()}
                </StyledToolbar>
            </>
        )
    }

}
